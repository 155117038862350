.project-section {
    padding: 3em 1em;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section-title {
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 1em;
    color: #fff;
  }
  
  .tab-container {
    display: flex;
    border: 2px solid #8b13f49c;
    justify-content: center;
    /* gap: 2px; */
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    /* margin: 0 auto; */
  }
  
  .tab-button {
    flex: 1;
    padding: 0.75em 0;
    font-size: 1em;
    font-weight: 500;
    color: #333;
    border: none;
    background: #f0f0f0;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .tab-button.active {
    background: #8b13f49c;
    color: #fff;
  }
  
  .project-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5em;
    max-width: 100%;
  }
  
  .project-card {
    background: #fff;
    padding: 1.5em;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .project-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1em;
  }
  
  .project-title {
    font-size: 1.25em;
    margin: 0.5em 0;
    color: #333;
  }

  .project-date {
    /* font-size: 1.25em; */
    margin: 0.5em 0;
    color: #333;
  }
  
  
  .project-description {
    font-size: 0.95em;
    color: #666;
  }
  
  .project-video {
    width: 100%;
    margin-top: 1em;
    border-radius: 8px;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .project-grid {
      grid-template-columns: 1fr;
    }
  }
  