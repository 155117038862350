.hamburger-menu {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000; /* High z-index to keep it on top */
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 30px;
    gap: 5px;
    z-index: 10;
  }
  
  .bar {
    height: 3px;
    width: 100%;
    background-color: #333;
    transition: transform 0.3s ease;
  }
  
  .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .bar.open:nth-child(2) {
    opacity: 0;
  }
  
  .bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  .menu-links {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    list-style: none;
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }
  
  .menu-links.show-menu {
    transform: translateX(0);
  }
  
  .menu-links li a {
    color: #fff;
    font-size: 1.5em;
    text-decoration: none;
  }
  
  .menu-links li a:hover {
    color: #007bff;
  }
  