.contact-section {
    padding: 3em 1em;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .section-title {
    font-size: 2em;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0.5em;
  }
  
  .contact-description {
    font-size: 1em;
    color: #666;
    margin-bottom: 1.5em;
  }
  
  .contact-icons {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
  
  .contact-icon {
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .contact-icon:hover {
    color: #0056b3;
  }
  